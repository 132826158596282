<template>
  <b-row>
    <b-col><brand /></b-col>
    <b-col><location /></b-col>
    <b-col><department /></b-col>
    <b-col><year /></b-col>
    <b-col cols="auto">
      <b-button
        variant="primary"
        :disabled="filterData.id_com_brand === null || filterData.id_com_location === null || filterData.id_com_department === null || filterData.year === null"
        @click="getReport"
      >
        <FeatherIcon icon="FilterIcon" />
        Görüntüle
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import Brand from '@/views/Reports/Csi/Brands/FilterData/Brand.vue'
import Location from '@/views/Reports/Csi/Brands/FilterData/Location.vue'
import Department from '@/views/Reports/Csi/Brands/FilterData/Department.vue'
import Year from '@/views/Reports/Csi/Brands/FilterData/Year.vue'

export default {
  name: 'FilterData',
  components: {
    BRow,
    BCol,
    BButton,
    Brand,
    Location,
    Department,
    Year,
  },
  props: {
    getReport: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filterData() {
      return this.$store.getters['csiBrandsReport/getFilterData']
    },
  },
  created() {
    this.filterItems()
  },
  methods: {
    filterItems() {
      this.$store.dispatch('csiBrandsReport/filterItems')
    },
  },
}
</script>

<template>
  <v-select
    id="year"
    v-model="filterData.year"
    :options="options.years"
    placeholder="Yıl"
  />
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'Year',
  components: {
    vSelect,
  },
  computed: {
    options() {
      return this.$store.getters['csiBrandsReport/getFilterItems']
    },
    filterData() {
      return this.$store.getters['csiBrandsReport/getFilterData']
    },
  },
}
</script>
